import { render, staticRenderFns } from "./MoleculeAvailabilitySpaceFilterResult.vue?vue&type=template&id=2a496fec&"
import script from "./MoleculeAvailabilitySpaceFilterResult.vue?vue&type=script&lang=js&"
export * from "./MoleculeAvailabilitySpaceFilterResult.vue?vue&type=script&lang=js&"
import style0 from "./MoleculeAvailabilitySpaceFilterResult.vue?vue&type=style&index=0&id=2a496fec&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/MoleculeAvailabilitySpaceFilterResult.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fmolecules%2Fproject%2FbuildingV2%2FMoleculeAvailabilitySpaceFilterResult.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomSimpleButton: require('/app/components/atoms/common/AtomSimpleButton.vue').default})
